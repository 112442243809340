<template>
  <div>
    <div class="text-justify">
      <b-button
          v-for="module in modules" v-bind:key="module.name"
          :variant="module.active ? 'outline-success' : 'outline-secondary'"
          class="btn-icon border m-1 p-1 d-inline-block"
          style="width: 190px; height: 150px"
          @click="toggleModule(module)"
      >
        <b-row class="d-flex">
          <b-col cols="12" style="height: 55px;">
            <div style="vertical-align: middle">
              <feather-icon v-if="module.type === 'feathericons'" :icon="module.icon" size="40"></feather-icon>
              <i v-if="module.type === 'fontawesome'" :class="{[module.icon]:true}" style="font-size: 40px"></i>
            </div>

          </b-col>
          <b-col cols="12">
            <strong class="d-block text-capitalize">{{ msg(module.name) }}</strong>
            <i>{{ msg(module.description) }}</i>
          </b-col>
        </b-row>
      </b-button>
    </div>
    <div>
      <b-button variant="primary" class="m-1" @click="save">{{msg('Save')}}</b-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'orgModules',
  data() {
    return {
      loaded: false,
      allModules: [],
      activeModules: [],
    }
  },
  props: {
    orgId: {
      type: Number,
      required: true
    }
  },
  watch: {
    orgId: {
      handler() {
       this.reloadOrgModules();
      },
      immediate: true
    }
  },
  created() {
    this.reloadModules()
  },
  computed: {
    modules() {
      return this.allModules
          .map(module => ({
            ...module,
            active: this.activeModules.includes(module.name)
          }))
    }
  },
  methods: {
    ...mapActions('notifications', {'notifyError':'error'}),
    ...mapActions('modules', ['getAllModules','getOrgModules','updateOrgModules']),
    reloadOrgModules(){
      let $this = this;
      this.getOrgModules(this.orgId).then((modules)=>{
        $this.activeModules = modules.map(module => module.name)
      })
    },
    reloadModules() {
      let $this = this
      this.getAllModules()
          .then(
              (modules) => {
                $this.allModules = modules
                $this.loaded = true
              }
          )
    },
    toggleModule(module) {
      console.log('toggleModule', module)
      if (module.active === true) {
        this.deActivateModule(module)
      } else {
        this.activateModule(module)
      }
    },
    activateModule(module) {
      if(module.parent != null){
        let parent = module.parent.find(parent=>!this.activeModules.includes(parent));
        if(parent != null) {
          this.notifyError({
            title: module.name,
            message: {
              text: 'permission %s is required in order to activate %s',
              arguments: [parent, module.name]
            }
          })
          return;
        }
      }
      this.activeModules.push(module.name)
    },
    deActivateModule(module) {
      let parents = this.allModules.filter(m=> m.parent != null && m.parent.includes(module.name)).map(m=>m.name)
      let toRemove = [module.name,...parents];
      this.activeModules = this.activeModules.filter(moduleName =>!toRemove.includes(moduleName))
    },
    save(){
      this.updateOrgModules({orgId:this.orgId,modulesName:this.activeModules});
    }
  }
}
</script>

<style scoped>

</style>

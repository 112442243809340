<template>
  <div>
    <b-card no-body>
      <b-card-header class="p-0 mb-1 bg-gradient-primary text-white">
        <h4 class="mb-0 text-white" style="padding: 0.5em">{{ msg('Organization') }}</h4>
      </b-card-header>
      <b-card-body>
        <org-details v-model="org" @save="saveOrg"/>
      </b-card-body>
    </b-card>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header class="p-0 mb-1 bg-gradient-primary text-white">
            <h4 class="mb-0 text-white" style="padding: 0.5em">{{ msg('Modules') }}</h4>
          </b-card-header>
          <b-card-body>
            <org-modules v-bind:org-id="org.id"/>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import OrgDetails from '@/components/panels/organization/orgDetails'
import OrgModules from '@/components/panels/organization/orgModules'

export default {
  name: 'OrganizationDetails',
  components: {
    OrgModules,
    OrgDetails
  },
  data() {
    return {
      org: {
        acronym: null,
        adminId: 0,
        adminName: null,
        contactName: null,
        country: null,
        crm: null,
        cssLink: null,
        email: null,
        hebCalendar: false,
        id: 1,
        lang: null,
        level: 0,
        logoLink: null,
        logoMinLink: null,
        modules: [],
        name: null,
        phone: null,
        price: 0,
        priceType: null,
        settings: {},
        type: 0,
        typeName: null,
        typeParents: null,
        vat: 0,
        website: null,
      },
      loaded: {
        org: false
      },
      error: { org: null }
    }
  },
  created() {
    this.refreshAll()
  },
  computed: {
    orgModulesName() {
      return this.org.modules.map(m => m.name)
    }
  },
  methods: {
    ...mapActions('organizations', ['get', 'update']),
    getOrgId() {
      let id = parseInt(this.$route.params.id)
      if (isNaN(id)) {
        this.error.org = 'Invalid client id'
        return null
      }
      return id
    },
    refreshAll() {
      this.refreshOrg()
    },
    refreshOrg() {
      let $this = this
      let id = this.getOrgId()
      if (id == null) return
      this.get(id)
          .then((org) => $this.org = org, (e) => this.error.org = e)
    },
    saveOrg() {
      this.update(this.org)
    }
  }
}
</script>

<style scoped>

</style>

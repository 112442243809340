<template>
<div>
  <validation-observer ref="refFormObserver">
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <b-row no-gutters class="w-100">
              <!-- Header: Personal Info -->

              <b-col cols="6" class="text-right">
                <div>
                  <b-button variant="outline-primary" class="btn-icon" id="org-upload-image">
                    <feather-icon icon="UploadIcon" />
                  </b-button>
                  <div>
                    <b-popover target="org-upload-image" size="xl">
                      <vue-dropzone id="pic-dropzone" :options="dropzoneOptions" :useCustomSlot=true :duplicateCheck="true" @vdropzone-complete="uploadComplete" @vdropzone-success="uploadSuccess">
                        <div class="dropzone-custom-content">
                          <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>
                          <div class="subtitle">...or click to select a file from your computer</div>
                        </div>
                      </vue-dropzone>
                    </b-popover>
                  </div>
                </div>
              </b-col>
              <b-col cols="6">

              </b-col>
            </b-row>

          </b-card-header>
          <b-card-body>
            <b-col cols="3">
              <div class="w-100 text-center px-5 h-100">
                <b-avatar
                        rounded="right"
                        variant="light-dark"
                        class="w-100 h-100"
                >
                  <template v-if="org.logoLink != null && org.logoLink.trim() !== ''">
                    <b-img
                            width="100"
                            :src="org.logoLink"></b-img>
                  </template>
                  <i class="far fa-building" v-else style="font-size: 100%"></i>
                </b-avatar>
              </div>
            </b-col>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col cols="12">
        <b-row>
          <!-- Field: name -->
          <b-col cols="4">
            <b-form-group :label="msg('Name')" label-for="org-name">
              <validation-provider #default="{ errors }" :name="msg('Name')" rules="required">
                <b-form-input id="org-name" v-model="org.name" :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Logo -->
          <b-col cols="4" md="4">
            <b-form-group :label="msg('Logo link')" label-for="org-logoLink">
              <b-form-input id="org-logoLink" v-model="org.logoLink"/>
            </b-form-group>
          </b-col>
          <!-- Field: Logo mini -->
          <b-col cols="4" md="4">
            <b-form-group :label="msg('Mini Logo link')" label-for="org-logoLink">
              <b-form-input id="org-logoMinLink" v-model="org.logoMinLink"/>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Header: Personal Info -->
    <div class="d-flex mt-1">
      <feather-icon
          icon="UserIcon"
          size="19"
      />
      <h4 class="mb-0 ml-50">
        {{ msg('Contact name') }}
      </h4>
    </div>
    <!-- Form: Personal Info Form -->
    <b-row class="mt-1">
      <!-- Field: Contact name -->
      <b-col cols="12" md="6" lg="4">
        <b-form-group :label="msg('Name')" label-for="org-contactName">
          <b-form-input id="org-contactName" v-model="org.contactName"/>
        </b-form-group>
      </b-col>
      <!-- Field: Phone -->
      <b-col cols="12" md="6" lg="4">
        <b-form-group :label="msg('Phone')" label-for="org-phone">
          <b-form-input id="org-phone" v-model="org.phone"/>
        </b-form-group>
      </b-col>
      <!-- Field: Email -->
      <b-col cols="12" md="6" lg="4">
        <b-form-group :label="msg('Email')" label-for="org-email">
          <validation-provider #default="{ errors }" :name="msg('Email')" rules="email">
            <b-form-input id="org-email" v-model="org.email" :state="errors.length > 0 ? false:null"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Header: Personal Info -->
    <div class="d-flex mt-1">
      <feather-icon
          icon="AtSignIcon"
          size="19"
      />
      <h4 class="mb-0 ml-50">
        {{ msg('Site') }}
      </h4>
    </div>
    <b-row class="mt-1">
      <!-- Field: Website -->
      <b-col cols="12" md="6" lg="4">
        <b-form-group :label="msg('Website')" label-for="org-website">
          <validation-provider #default="{ errors }" :name="msg('Website')" rules="url">
            <b-form-input id="org-website" v-model="org.website" :state="errors.length > 0 ? false:null"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="4">
        <b-form-group :label="msg('CRM')" label-for="org-crm">
          <validation-provider #default="{ errors }" :name="msg('CRM')" rules="url">
            <b-form-input id="org-crm" v-model="org.crm" :state="errors.length > 0 ? false:null"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="4">
        <b-form-group :label="msg('CSS link')" label-for="org-cssLink">
          <validation-provider #default="{ errors }" :name="msg('CSS link')" rules="url">
            <b-form-input id="org-cssLink" v-model="org.cssLink" :state="errors.length > 0 ? false:null"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <b-button variant="outline-primary" @click="$emit('save')" >{{msg("save")}}</b-button>
    </div>
  </validation-observer>
</div>
</template>

<script>
  import vue2Dropzone from "vue2-dropzone";

export default {
  name: 'orgDetails',
  components: {vueDropzone: vue2Dropzone,
  },
  data(){
    return{
      dropzoneOptions: {},


    }
  },
  props:{
    value:Object
  },
  computed:{
    org:{
      get() {
        if (this.value == null) {
          console.error('organization: value (v-model) is null!')
          return {}
        }
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  created() {
    this.loadDropZone();
  },
  methods:{
    loadDropZone(){
      let $this = this;
      this.dropzoneOptions = {
        url: window.config.apiUrl + 'organization/upload/img',
        headers: { 'Authorization':`Bearer ${localStorage.Bearer}`, 'orgId':$this.org.id},
        createImageThumbnails:false,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        maxFilesize: 50000,
        acceptedFiles: 'image/*',

      }
    },
    uploadComplete(file,response){
      console.log('uploadComplete',{file,response})
    },
    uploadSuccess(file, response){
      try {
        if(response.data == null || response.data['Organization'] == null)
          return;
        this.org.images = response.data['Organization']['images'];
      }catch (e) {
        console.error('uploadSuccess error',e)
      }
    },
  }
}
</script>

<style scoped>

</style>
